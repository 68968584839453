<template lang="pug">
.product
  .header(:onClick="() => { open = hasBody && !open; }", :class="{ clickable: hasBody }")
    .name {{ product.name }}
    .toggle(v-if="hasBody")
      fa(:icon="['fas', open ? 'chevron-down' : 'chevron-right']")
  .body(v-if="hasBody", v-show="open")
    .description(v-if="product.description != null")
      h4 Sobre o produto

      p {{ product.description }}

    .matching-traits(v-if="hasMatchingTraits")
      h4 Por que ele é perfeito para você?

      ul
        li(v-for="matchingTrait in matchingTraits", v-html="matchingTrait")

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { SubscriptionBoxProduct } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import { useTraitMatchingView } from './SubscriptionBoxOrderProductTraitView'

const props = defineProps({
  product: {
    type: Object as PropType<SubscriptionBoxProduct>,
    required: true,
  },
})

const open = ref<Boolean>(false)

const { hasMatchingTraits, matchingTraits } = useTraitMatchingView(props.product)

const hasBody = computed(() => hasMatchingTraits.value || props.product.description != null)
</script>
<style lang="sass" scoped>
.product
  background: #FFECDF
  margin: 8px auto
  border-radius: 8px

  .header
    display: flex
    padding: 8px 16px

    &.clickable
      cursor: pointer

    .name
      flex-grow: 1
      font-weight: bold

    .toggle
      width: 24px
      font-size: 18px
      text-align: center

  .body
    padding: 16px
    text-align: justify
    hyphens: auto

    .description
      white-space: pre-line

      h4
        margin: 0 0 16px

      p
        margin: 0

    .matching-traits
      position: relative
      margin-top: 16px
      padding: 16px
      border-radius: 8px
      background-color: #63CAE1

      ul
        list-style: none
        padding-left: 8px

        li::before
          content: "✓"
          width: 16px
          padding-right: 8px
          font-weight: bold

      &::before
        position: absolute
        display: block
        top: 4px
        left: 4px
        width: calc(100% - 8px)
        height: calc(100% - 8px)
        border: dashed 2px black
        border-radius: 8px
        content: ''
        box-sizing: border-box
</style>
