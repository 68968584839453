<template lang="pug">
.order-magazine(v-if="hasMagazine")
  h2 Revista Magenta

  .magazine
    a(:href="magazineUrl")
      img(:src="magazineThumbnailUrl", width="298", height="421")

    .magazine-description(v-if="edition?.magazineDescription")
      .magazine-description-text
        h3 Edição {{ magazineTitle }}

        p {{ edition.magazineDescription }}

      .magazine-actions
        Button(
          type="text",
          label="Ler revista",
          :href="magazineUrl",
          :internal="false",
          target="_blank"
        )
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { SubscriptionBoxOrder, SubscriptionBoxEdition } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import { subscriptionBoxOrderTitle } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

const props = defineProps({
  order: {
    type: Object as PropType<SubscriptionBoxOrder | null>,
    required: true
  },
  edition: {
    type: Object as PropType<SubscriptionBoxEdition | null>,
    required: true,
  },
  showTitle: {
    type: Boolean,
    default: false,
  },
})

const initialMagazineEdition = "202104"

const hasMagazine = computed(() => {
  return props.order != null && props.order.status != 'CANCELED' && props.order.edition >= initialMagazineEdition
})

const magazineTitle = computed(() => {
  if (!hasMagazine.value) {
    return undefined
  }

  return subscriptionBoxOrderTitle(props.order!)
})

const magazineUrl = computed(() => {
  if (!hasMagazine.value) {
    return undefined
  }

  return `https://boxmagenta.com.br/revista/#/${props.order!.edition}/${props.order!.id}`
})

const magazineThumbnailUrl = computed(() => {
  if (!hasMagazine.value) {
    return undefined
  }

  return `https://static.boxmagenta.com.br/magazine/cover/${props.order!.edition}-cover.jpg`
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.order-magazine
  margin-bottom: 16px

  .magazine
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 16px
    align-items: flex-start
    justify-content: center

    img
      border-radius: 4px
      width: 100%
      max-width: 220px
      height: auto
      flex-grow: 0
      flex-shrink: 0

    .magazine-description
      flex-grow: 1
      width: 220px
      background-color: $beige
      color: #000
      border-radius: 8px

      .magazine-description-text
        padding: 16px
        white-space: pre-line

        h3
          margin: 0 0 16px

      .magazine-actions
        padding-bottom: 8px
</style>
