<template lang="pug">
.order-status-step(:class="{ success: isSuccess, failure: isFailure }")
  b {{ status }}
  span(v-if="date")
    b :&nbsp;
    | {{ formattedDate }}
  | &nbsp;
  slot

  .order-status-step--status-step-circle
  .order-status-step--status-step-line
</template>
<script setup lang="ts">
const props = defineProps({
  status: String,
  date: String,
})

const isFailure = computed(
  () => props.status == "Retornada" || props.status == "Extraviada"
)

const isSuccess = computed(() => !isFailure.value && props.date != null)

const formattedDate = computed(() => {
  if (props.date != null) {
    const date = new Date(props.date)

    return (
      date.getDate().toString().padStart(2, "0") +
      "/" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear().toString().padStart(4, "0")
    );
  } else {
    return null
  }
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.order-status-step
  position: relative
  min-height: 24px
  padding-left: 24px
  font-size: 16px
  text-align: left
  color: $inactiveTextColor

  .order-status-step--status-step-circle
    position: absolute
    top: 4px
    left: 0
    height: 12px
    width: 12px
    border: 2px solid $inactiveTextColor
    border-radius: 6px

  .order-status-step--status-step-line
    position: absolute
    top: -8px
    left: 5px
    height: 14px
    border-left: 2px dotted $inactiveTextColor

  &:first-child
    .order-status-step--status-step-line
      display: none

  &.success
    color: $textColor

    .order-status-step--status-step-circle
      border-color: $acquaBlue
      background-color: $acquaBlue

    .order-status-step--status-step-line
      border-left: 2px solid $acquaBlue

  &.failure
    color: $darkMagenta

    .order-status-step--status-step-circle
      border-color: $darkMagenta
      background-color: $darkMagenta

    .order-status-step--status-step-line
      border-left: 2px solid $darkMagenta
</style>