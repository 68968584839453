import type { SubscriptionBoxProduct } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

export function useTraitMatchingView(product: SubscriptionBoxProduct) {
  function translateTraits(traits: string[]): string[] {
    return traits
    .map((trait) => {
      // Skip scent trait if product is not a fragrances or a body lotion
      if (trait.startsWith("SKIN_PREFERRED_SCENT")
        && !product.traits.includes("SKIN_PRODUCT_TYPE_FRAGRANCE")
        && !product.traits.includes("SKIN_PRODUCT_TYPE_BODY_LOTION")) {
        return null
      }

      return matchingTraitReason[trait]
    })
    .filter(reason => reason != null)
    .map(trait => trait!)
  }

  const matchingTraits = computed(() => {
    return ([] as string[])
      .concat(translateTraits(product.matchingTraits.filter(trait => trait.startsWith("MAKEUP_PRODUCT_TYPE") || trait.startsWith("SKIN_PRODUCT_TYPE") || trait.startsWith("HAIR_PRODUCT_TYPE"))))
      .concat(translateTraits(product.matchingTraits.filter(trait => !(trait.startsWith("MAKEUP_PRODUCT_TYPE") || trait.startsWith("SKIN_PRODUCT_TYPE") || trait.startsWith("HAIR_PRODUCT_TYPE")))))
  })

  const hasMatchingTraits = computed(() => matchingTraits.value.length > 0)

  return { hasMatchingTraits, matchingTraits }
}


interface TraitDict {
  [id: string]: string | null
}

const matchingTraitReason = {
  // Makeup Product Type
  "MAKEUP_PRODUCT_TYPE_REGULAR_LIPSTICK": null,
  "MAKEUP_PRODUCT_TYPE_LIQUID_LIPSTICK": null,
  "MAKEUP_PRODUCT_TYPE_LIP_TINT": null,
  "MAKEUP_PRODUCT_TYPE_GLOSS": null,
  "MAKEUP_PRODUCT_TYPE_LIP_LINER": null,
  "MAKEUP_PRODUCT_TYPE_FOUNDATION": null,
  "MAKEUP_PRODUCT_TYPE_CONCEALER": null,
  "MAKEUP_PRODUCT_TYPE_PRIMER": null,
  "MAKEUP_PRODUCT_TYPE_MAKEUP_FIXER": null,
  "MAKEUP_PRODUCT_TYPE_BLUSH": null,
  "MAKEUP_PRODUCT_TYPE_FACE_POWDER": null,
  "MAKEUP_PRODUCT_TYPE_BRUSH": null,
  "MAKEUP_PRODUCT_TYPE_MAKEUP_SPONGE": null,
  "MAKEUP_PRODUCT_TYPE_ILLUMINATOR": null,
  "MAKEUP_PRODUCT_TYPE_BRONZER": null,
  "MAKEUP_PRODUCT_TYPE_PENCIL_EYELINER": null,
  "MAKEUP_PRODUCT_TYPE_EYE_PIGMENT": null,
  "MAKEUP_PRODUCT_TYPE_EYELINER": null,
  "MAKEUP_PRODUCT_TYPE_EYESHADOW": null,
  "MAKEUP_PRODUCT_TYPE_MASCARA": null,
  "MAKEUP_PRODUCT_TYPE_EYEBROW_PRODUCTS": null,
  "MAKEUP_PRODUCT_TYPE_NAIL_POLISH": null,

  // Hair Product Type
  "HAIR_PRODUCT_TYPE_SHAMPOO": null,
  "HAIR_PRODUCT_TYPE_CONDITIONER": null,
  "HAIR_PRODUCT_TYPE_DRY_SHAMPOO": null,
  "HAIR_PRODUCT_TYPE_CAPILLARY_MASK": null,
  "HAIR_PRODUCT_TYPE_REPAIRING_OIL": null,
  "HAIR_PRODUCT_TYPE_LEAVE_IN": null,
  "HAIR_PRODUCT_TYPE_HAIR_FIXER": null,

  // Skin Product Type
  "SKIN_PRODUCT_TYPE_LIQUID_FACIAL_SOAP": null,
  "SKIN_PRODUCT_TYPE_LIQUID_BODY_SOAP": null,
  "SKIN_PRODUCT_TYPE_SOLID_FACIAL_SOAP": null,
  "SKIN_PRODUCT_TYPE_SOLID_BODY_SOAP": null,
  "SKIN_PRODUCT_TYPE_HAND_LOTION": null,
  "SKIN_PRODUCT_TYPE_BODY_OIL": null,
  "SKIN_PRODUCT_TYPE_BODY_LOTION": null,
  "SKIN_PRODUCT_TYPE_FACE_MOISTURIZER": null,
  "SKIN_PRODUCT_TYPE_BODY_EXFOLIATOR": null,
  "SKIN_PRODUCT_TYPE_FACIAL_EXFOLIATOR": null,
  "SKIN_PRODUCT_TYPE_LIP_EXFOLIATOR": null,
  "SKIN_PRODUCT_TYPE_FACIAL_CLEANSING": null,
  "SKIN_PRODUCT_TYPE_LIP_MOISTURIZER": null,
  "SKIN_PRODUCT_TYPE_SUNSCREEN": null,
  "SKIN_PRODUCT_TYPE_FACIAL_TONER": null,
  "SKIN_PRODUCT_TYPE_FACIAL_MASK": null,
  "SKIN_PRODUCT_TYPE_EYE_MASK": null,
  "SKIN_PRODUCT_TYPE_EYE_AREA_TREATMENT": null,
  "SKIN_PRODUCT_TYPE_FACIAL_SERUM": null,
  "SKIN_PRODUCT_TYPE_MAKEUP_REMOVER": null,
  "SKIN_PRODUCT_TYPE_FRAGRANCE": null,
  "SKIN_PRODUCT_TYPE_DEODORANT": null,
  "SKIN_PRODUCT_TYPE_FEET_CARE": null,

  // Skin
  "SKIN_COLOR_TYPE_1": "Ideal para sua <b>pele muito clara</b>",
  "SKIN_COLOR_TYPE_2": "Ideal para sua <b>pele clara</b>",
  "SKIN_COLOR_TYPE_3": "Ideal para sua <b>pele média clara</b>",
  "SKIN_COLOR_TYPE_4": "Ideal para sua <b>pele média</b>",
  "SKIN_COLOR_TYPE_5": "Ideal para sua <b>pele média escura</b>",
  "SKIN_COLOR_TYPE_6": "Ideal para sua <b>pele escura</b>",

  "SKIN_OILNESS_DRY": "Especialmente escolhido para sua <b>pele seca</b>",
  "SKIN_OILNESS_NORMAL": "Especialmente escolhido para sua <b>pele normal</b>",
  "SKIN_OILNESS_MIXED": "Especialmente escolhido para sua <b>pele mista</b>",
  "SKIN_OILNESS_OILY": "Especialmente escolhido para sua <b>pele oleosa</b>",

  "SKIN_ISSUE_DRYNESS": "Indicado para quem, assim como você, sofre com <b>ressecamento</b>",
  "SKIN_ISSUE_EXCESSIVE_OILNESS": "Indicado para quem, assim como você, sofre com <b>oleosidade excessiva</b>",
  "SKIN_ISSUE_ACNE": "Indicado para você que quer tratar (ou prevenir!) <b>acne</b>",
  "SKIN_ISSUE_SENSITIVITY": "Indicado para você que tem <b>pele sensível</b>",
  "SKIN_ISSUE_WRINKLES": "Indicado para você que quer tratar (ou prevenir!) <b>rugas</b>",
  "SKIN_ISSUE_SKIN_STAINS": "Indicado para você que quer tratar (ou prevenir!) <b>manchas</b>",
  "SKIN_ISSUE_DARK_CIRCLES_AROUND_EYES": "Indicado para quem, assim como você, sofre com <b>olheiras</b>",

  "SKIN_PREFERRED_SCENT_FRESH": "Possui aroma <b>fresco</b>, como você ama!",
  "SKIN_PREFERRED_SCENT_WOODY": "Possui aroma <b>amadeirado</b>, como você ama!",
  "SKIN_PREFERRED_SCENT_CITRUS": "Possui aroma <b>cítrico</b>, como você ama!",
  "SKIN_PREFERRED_SCENT_SWEET": "Possui aroma <b>doce</b>, como você ama!",
  "SKIN_PREFERRED_SCENT_FRUITY": "Possui aroma <b>frutado</b>, como você ama!",
  "SKIN_PREFERRED_SCENT_FLORAL": "Possui aroma <b>floral</b>, como você ama!",

  // Hair
  "HAIR_COLOR_BLOND": "Adequado para <b>cabelos loiros</b>, como o seu!",
  "HAIR_COLOR_ASHEN": "Adequado para <b>cabelos grisalhos</b>, como o seu!",
  "HAIR_COLOR_BROWN": "Adequado para <b>cabelos castanhos</b>, como o seu!",
  "HAIR_COLOR_BLACK": "Adequado para <b>cabelos pretos</b>, como o seu!",
  "HAIR_COLOR_RED": "Adequado para <b>cabelos ruivos</b>, como o seu!",
  "HAIR_COLOR_COLORED": "Adequado para <b>cabelos coloridos</b>, como o seu!",

  "HAIR_TYPE_STRAIGHT": "Perfeito para seus <b>cabelos lisos</b>!",
  "HAIR_TYPE_WAVY": "Perfeito para seus <b>cabelos ondulados</b>!",
  "HAIR_TYPE_CURLY": "Perfeito para seus <b>cabelos cacheados</b>!",
  "HAIR_TYPE_COILY": "Perfeito para seus <b>cabelos crespos</b>!",

  "HAIR_OILNESS_DRY": "Especial para <b>cabelos secos</b>",
  "HAIR_OILNESS_NORMAL": "Especial para <b>cabelos normais</b>",
  "HAIR_OILNESS_OILY": "Especial para <b>cabelos oleosos</b>",

  "HAIR_CHEMICAL_NATURAL": "Indicado para <b>cabelos naturais</b>",
  "HAIR_CHEMICAL_CHEMICALLY_TREATED": "Indicado para <b>cabelos quimicamente tratados</b>",

  "HAIR_ISSUE_FRIZZ": "Indicado para quem, assim como você, sofre com <b>frizz</b>",
  "HAIR_ISSUE_SPLIT_ENDS": "Indicado para quem, assim como você, sofre com <b>pontas duplas</b>",
  "HAIR_ISSUE_DRYNESS": "Indicado para quem, assim como você, sofre com <b>cabelo ressecado</b>",
  "HAIR_ISSUE_EXCESSIVE_OILNESS": "Indicado para quem, assim como você, sofre com <b>cabelo excessivamente oleoso</b>",
  "HAIR_ISSUE_TOO_LITTLE_VOLUME": "Indicado para quem, assim como você, sofre com <b>cabelo com pouco volume</b>",
  "HAIR_ISSUE_TOO_MUCH_VOLUME": "Indicado para quem, assim como você, sofre com <b>cabelo com muito volume</b>",

  // Lipstick Color
  "LIPSTICK_COLOR_NUDE": "Batom <b>nude</b>, que você adora!",
  "LIPSTICK_COLOR_PINK": "Batom <b>rosa</b>, que você adora!",
  "LIPSTICK_COLOR_RED": "Batom <b>vermelho</b>, que você adora!",
  "LIPSTICK_COLOR_PURPLE": "Batom <b>roxo</b>, que você adora!",
  "LIPSTICK_COLOR_EXOTIC_COLORS": "Batom de <b>tom exótico</b>, que você adora!",

  // Shadow Color
  "SHADOW_COLOR_NEUTRAL": "Sombra de cor <b>neutra</b>, do jeitinho que você gosta!",
  "SHADOW_COLOR_COLORFUL": "Sombra <b>colorida</b>, do jeitinho que você gosta!",

  // Eyeliner Color
  "EYELIINER_COLOR_BEIGE": "Na cor <b>bege</b>, que a gente sabe que você ama!",
  "EYELIINER_COLOR_BROWN": "Na cor <b>marrom</b>, que a gente sabe que você ama!",
  "EYELIINER_COLOR_BLACK": "Na cor <b>preto</b>, que a gente sabe que você ama!",
  "EYELIINER_COLOR_COLORFUL": "Na cor <b>colorido</b>, que a gente sabe que você ama!",

} as TraitDict