<template lang="pug">
.order-products
  h2 Produtos da box

  .list(v-if="showProducts && productsByBrand != null")
    .product-list
      .product-brand(v-for="[brand, products] in productsByBrand")
        h3 {{ brand }}
        SubscriptionBoxProductItem(v-for="product in products", :product="product")

    .additional-products(v-if="additionalProducts != null && additionalProducts.length > 0")
      h2 Presentes

      .product-list
        SubscriptionBoxAdditionalProductItem(v-for="additionalProduct in additionalProducts", :product="additionalProduct")

  .spy-products(v-else-if="showSpyProducts && productsByBrand != null", :onClick="toggleShowProducts")
    .spy-icon
      fa(:icon="['fas', 'eye-slash']")

    p Para espiar quais produtos você vai receber nesta box, clique aqui

  .no-products(v-else)
    | Ainda não há produtos selecionados para essa box
</template>
<script setup lang="ts">
import type { PropType } from 'vue'

import { useGtm } from '@gtm-support/vue-gtm'

import type {
  SubscriptionBoxOrder,
  SubscriptionBoxProduct,
  SubscriptionBoxAdditionalProduct
} from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import SubscriptionBoxProductItem from './SubscriptionBoxProductItem.vue'
import SubscriptionBoxAdditionalProductItem from './SubscriptionBoxAdditionalProductItem.vue'

const props = defineProps({
  order: {
    type: Object as PropType<SubscriptionBoxOrder>,
    required: true,
  },
  products: {
    type: Object as PropType<SubscriptionBoxProduct[] | null>,
  },
  additionalProducts: {
    type: Object as PropType<SubscriptionBoxAdditionalProduct[] | null>,
  },
})

const gtm = useGtm()
const productsByBrand = ref<Map<String, SubscriptionBoxProduct[]> | null>(null)
const showProducts = ref<Boolean>(props.order.status == "DELIVERED")
const showSpyProducts = ref<Boolean>(["READY_TO_POST", "IN_TRANSIT", "AWAITING_CUSTOMER_PICKUP"].indexOf(props.order.status) >= 0)

const toggleShowProducts = () => {
  showProducts.value = true;

  const dataLayer = gtm?.dataLayer();

  if (dataLayer) {
    dataLayer.push({
      event: "spy-products",
      "subscriptionBoxEdition": props.order.edition,
      "subscriptionBoxOrderId": props.order.id
    })
  }
}

onMounted(async () => {
  productsByBrand.value =
    props.products?.reduce((map: Map<String, SubscriptionBoxProduct[]>, product: SubscriptionBoxProduct) => {
      map.set(product.brandName, map.get(product.brandName) || []);
      map.get(product.brandName)?.push(product);

      return map;
    }, new Map<String, SubscriptionBoxProduct[]>()) || null
})
</script>
<style lang="sass" scoped>
.order-products
  margin-bottom: 32px

  .product-brand
    margin-bottom: 32px

  .spy-products
    position: relative
    background: #FFECDF
    margin: 8px auto
    padding: 24px
    border-radius: 8px
    text-align: center
    color: #6a6a6a
    cursor: pointer

    .spy-icon
      margin-bottom: 8px
      font-size: 32px

    p
      margin: 0

    &::before
      position: absolute
      display: block
      top: 4px
      left: 4px
      width: calc(100% - 8px)
      height: calc(100% - 8px)
      border: dashed 2px #9a9a9a
      border-radius: 8px
      content: ''
      box-sizing: border-box

  .no-products
    position: relative
    padding: 24px
    width: 100%
    text-align: center
    font-size: 18px
    color: #AAA

    &::before
      position: absolute
      display: block
      top: 4px
      left: 4px
      width: calc(100% - 8px)
      height: calc(100% - 8px)
      border: dashed 2px #dedede
      border-radius: 8px
      content: ''
      box-sizing: border-box
</style>
