<template lang="pug">
.order(v-if="!loaded")
  .order-loading
    Loading

.order(v-else-if="order != null")
  h1 {{ orderTitle }}

  p(v-html="orderStatusDescription")

  .order-status
    .order-status-steps
      SubscriptionBoxOrderStatusStep(status="Pagamento", :date="order?.createdAt")
      SubscriptionBoxOrderStatusStep(status="Em Produção", :date="order?.inProductionAt")
      SubscriptionBoxOrderStatusStep(status="Pronta para envio", :date="order?.readyToPostAt")
      SubscriptionBoxOrderStatusStep(status="Enviada", :date="order?.inTransitAt")
      SubscriptionBoxOrderStatusStep(:status="finalStepStatus", :date="finalStepDate")

    .order-status-actions
      Button(
        type="text",
        label="Rastrear entrega",
        :to="orderTrackingUrl",
        :disabled="orderTrackingUrl == null"
      )

  .order-actions
    Button(
      type="text",
      label="Ver nota fiscal",
      :href="orderNfeUrl",
      :internal="false",
      :disabled="!enableNfeButton",
      target="_blank"
    )
    Button(
      v-if="!hasFeedback",
      type="text",
      label="Avaliar box",
      :to="orderFeedbackUrl",
      :disabled="orderFeedbackUrl == null"
    )

    Button(v-else, type="text", label="Box avaliada", disabled)

  SubscriptionBoxOrderProducts(:order="order", :products="products", :additional-products="additionalProducts")

  SubscriptionBoxOrderMagazine(:edition="edition", :order="order")

  Benefits(:subscription="primarySubscription", :benefits="benefits")
</template>
<script setup lang="ts">
import SubscriptionBoxOrderStatusStep from "./SubscriptionBoxOrderStatusStep.vue"
import SubscriptionBoxOrderMagazine from "./Magazine/SubscriptionBoxOrderMagazine.vue"
import SubscriptionBoxOrderProducts from "./Products/SubscriptionBoxOrderProducts.vue"

import Benefits from "../../../Benefits/Benefits.vue"

import { useOrderData } from "./SubscriptionBoxOrderData"
import { useOrderInfoView } from "./SubscriptionBoxOrderInfoView"

definePageMeta({
  requiresAuth: true
})

const {
  loaded,
  order,
  edition,
  feeback,
  hasFeedback,
  products,
  additionalProducts,
  primarySubscription,
  benefits
} = await useOrderData()

const {
  orderTitle,
  orderStatusDescription,
  orderTrackingUrl,
  orderFeedbackUrl,
  orderNfeUrl,
  enableNfeButton,
  finalStepStatus,
  finalStepDate,
} = useOrderInfoView(order)
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.order
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  h1
    margin-bottom: 4px

  .order-loading
    display: flex
    height: 320px
    justify-content: center
    align-items: center

  .order-rating
    font-size: 16px

    .unrated
      color: $inactiveTextColor

    .checked
      position: relative

    .checked:before
      position: absolute
      top: 0
      left: 0
      content: "\2605"
      color: $magenta

  .order-status
    background-color: $beige
    border-radius: 8px

    .order-status-steps
      padding: 16px
      padding-bottom: 0

    .order-status-actions
      margin-top: 8px

      .button
        padding: 16px

  .order-actions
    .button
      padding: 16px
</style>
<style lang="sass">
@import '~/assets/styles/variables'

.order
  span.magenta-text
    color: $magenta
</style>
